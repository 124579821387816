@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}
* {
  box-sizing: border-box;
  /* transition: all 0.1s ease-in; */
  font-family: "Jost", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  background: rgb(100, 0, 123);
  background: radial-gradient(circle, #f3f9ff, #d1dce2);
}

.bg-gradient-radial-circle {
  background-image: radial-gradient(to right, #cad5db, #f1f5f8);
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  line-height: 1.5;
  font-size: 0.875rem;
  outline: none;
}

input:focus {
  outline: 2px #6ad61d solid;
}

.primary-btn {
  cursor: pointer;
  appearance: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: #3b82f6;
  border: 1px solid transparent;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.primary-btn:hover {
  background-color: #2563eb;
}

.secondary-btn {
  cursor: pointer;
  appearance: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: #6b7280;
  border: 1px solid transparent;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.secondary-btn:hover {
  background-color: #4b5563;
}

/* section-one */

.section-one {
  background-image: url("../src/assets/header.jpg");
  background-size: auto;
  background-repeat: no-repeat;
  background-color: white;
  /* background-position: top left; */
}
/* 
.slick-slide {
  background-color: white;
  margin: 0 10px;
} */

.slick-slide {
  height: auto !important; /* Reset height to auto */
}

.featured-job-slide {
  padding: 10px; /* Adjust as needed */
}
